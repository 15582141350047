@import '../../../styles/colors';
@import '../../../styles/mixin';

.NotificationQueue {
  position: fixed;
  z-index: $zi-notification-queue;
  top: 0;
  right: 0;
  @extend .bsbb;
  padding: 10px;
}
