@import 'colors';
@import 'mixin';

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: $background;
}

body,
div,
input,
select,
textarea,
a,
button,
pre {
  vertical-align: top;
  border: 0;
  outline: none;
  font-family: 'Figtree', sans-serif;
  color: $text-color;
  font-size: 14px;
}
textarea {
  resize: none;
}

a,
button {
  @extend .trstn;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    @extend .trstn;
  }
}

button {
  cursor: pointer;
  background: transparent;
}

input[type='submit'],
button {
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    @include opacity(0.5);
  }
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--input-font-color);
  opacity: 0.5; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--input-font-color);
  opacity: 0.5;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--input-font-color);
  opacity: 0.5;
}

label {
  @extend .display-flex;
  @extend .flex-direction-column;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  width: 100%;
  color: $my-blue;
  input,
  textarea,
  select {
    margin-top: 5px;
  }
  &:not(:first-of-type) {
    padding-top: 20px;
  }
  p {
    margin-top: 5px;
    color: $text-color;
  }
}

input,
textarea,
select {
  background: transparent;
  padding: 10px;
  @include border-radius(5px);
  @extend .bsbb;
  height: 42px;
  width: 100%;
  border: 1px solid transparent;
  @extend .trstn;
  border: 1px solid $border;
  &:focus {
    border: 1px solid $my-blue;
    @extend .trstn;
  }
}

textarea {
  min-height: 110px;
}

h1 {
  color: $my-blue;
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 18px;
  &.mt {
    margin-top: 20px;
  }
}

// ::selection {
//   background-color: $selection;
//   color: $white;
// }

// ::-moz-selection {
//   background-color: $selection;
//   color: $white;
// }
