@import 'colors';
@import 'mixin';

.blue-button {
  background: $my-blue;
  @include border-radius(5px);
  padding: 10px;
  @extend .display-flex;
  @extend .flex-center;
  margin-top: 20px;
  min-width: 100px;
  color: $white;
  height: 40px;
  @extend .bsbb;
  &:hover {
    background: $my-blue-hover;
  }
}

.form-buttons {
  @extend .display-flex;
  @extend .flex-direction-row;
  @extend .flex-center-end;

  button {
    @extend .blue-button;
  }
}
