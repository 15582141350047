@import '../../../../styles/colors';
@import '../../../../styles/mixin';

.Notification {
  @media screen and (min-width: 320px) {
    width: 100%;
  }
  @media screen and (min-width: 480px) {
    width: 400px;
  }
  @extend .display-flex;
  @extend .flex-center-start;
  background: $my-green;
  color: $white;
  word-break: break-word;
  overflow-wrap: anywhere;
  line-height: 1.5em;
  @include box-shadow(0, 0, 5px, 0, rgba($grey, 0.5));
  @extend .bsbb;
  padding: 20px;
  word-wrap: break-word;
  @include border-radius(5px);
  cursor: pointer;
  @extend .trstn;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  &:not(:first-of-type) {
    margin-top: 10px;
  }
  &:hover {
    @extend .trstn;
    @include box-shadow(0, 0, 5px, 0, rgba($grey, 0.9));
  }
}

.Bar {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  content: '';
  height: 5px;
  background: rgba($white, 0.3);
  animation: move 5s linear;
}

@keyframes move {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.TypeError {
  background: $my-red;
}
