@import '../../../styles/colors';
@import '../../../styles/mixin';

.UlSubMenu {
  @extend .display-flex;
  @extend .flex-direction-row;
  @extend .flex-wrap;
  li {
    margin-bottom: 20px;
    &:not(:last-of-type) {
      margin-right: 20px;
    }
    a {
      @extend .display-flex;
      @extend .flex-center;
      border: 1px solid $border;
      padding: 10px;
      @include border-radius(5px);
    }
  }
}

.LinkActive {
  background: $hover;
}
