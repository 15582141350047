@import '../../../styles/colors';
@import '../../../styles/mixin';

.AuthLayout {
  @extend .display-flex;
  @extend .flex-center;
  @extend .flex-direction-column;
}

.Main {
  padding: 10px 0;
  width: 100%;
  @extend .display-flex;
  @extend .flex-center;
}

.Container {
  @extend .responsive-width;
  @extend .bsbb;
  padding: 10px;
}
