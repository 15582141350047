@import '../../../../styles/colors';
@import '../../../../styles/mixin';

.UlLinks {
  font-weight: 600;
  width: 100%;
  @extend .bsbb;
  @extend .display-flex;
  @extend .flex-direction-row;
  @extend .flex-center-start;
  @extend .flex-wrap;
  li {
    @extend .display-flex;
    @extend .flex-direction-row;
    @extend .flex-center-start;
    &:not(:last-of-type) {
      &:after {
        content: '/';
        display: block;
        color: $text-color;
      }
    }
    white-space: nowrap;
    span,
    a {
      padding: 5px 10px;
      margin: 0 5px;
      text-transform: capitalize;
    }
    a {
      @include border-radius(5px);
      color: $my-blue;
      &:hover {
        background: $white;
      }
    }
  }
}
