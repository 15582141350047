@import '../../../styles/colors';
@import '../../../styles/mixin';

.LoaderBkg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $white;
  z-index: $zi-page-loader;
  @extend .display-flex;
  @extend .flex-center;
}

.Loader {
  width: 60px;
  height: 60px;
  border: 4px dotted $my-red;
  @include border-radius(50%);
  animation: rotation 3s linear infinite;
}

.Logo {
  position: absolute;
  z-index: 1;
  font-size: 1.5rem;
  img {
    width: 40px;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
