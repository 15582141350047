@import '../../../styles/colors';
@import '../../../styles/mixin';

.Layout {
  height: 100vh;
}

.Footer {
  width: 100%;
  text-align: center;
  color: $grey;
  font-size: 12px;
  @extend .bsbb;
  padding-bottom: 10px;
  @extend .display-flex;
  @extend .flex-direction-column;
  @extend .flex-center;
}

.Logo {
  height: 20px;
  margin-top: 10px;
}
