@import '../../../styles/colors';
@import '../../../styles/mixin';

.Container {
  border: 1px solid $border;
  @include border-radius(10px);
  width: 100%;
  background: $hover;
  padding: 10px;
  @extend .bsbb;
}

.Header {
  @extend .display-flex;
  @extend .flex-direction-row;
  margin-bottom: 10px;
  min-height: 40px;
}

.UlHeader {
  @extend .display-flex;
  @extend .flex-direction-row;
  @extend .flex-top-end;
  width: 100%;
  li {
    &:not(:first-of-type) {
      margin-left: 10px;
    }
    a,
    button {
      background: $my-blue;
      @include border-radius(5px);
      padding: 10px;
      @extend .display-flex;
      @extend .flex-center;
      width: 40px;
      color: $white;
      height: 40px;
      @extend .bsbb;
      &:hover {
        background: $my-blue-hover;
      }
    }
  }
}

.Body {
  background: $white;
  border: 1px solid $border;
  @include border-radius(5px);
  padding: 20px;
}
