@import '../../../../styles/colors';
@import '../../../../styles/mixin';

.Nav {
  @extend .display-flex;
  @extend .flex-direction-row;
  @extend .flex-justify-between;
  width: 100%;
  background: $white;
  height: 60px;
  @extend .bsbb;
  padding: 10px;
  border-bottom: 1px solid $border;
}

.Brand {
  margin-right: 20px;
  img {
    width: 40px;
  }
}

.MenuContainer {
  @extend .display-flex;
  @extend .flex-center;
  width: 100%;
}

.UlNav {
  @extend .display-flex;
  @extend .flex-center;
  @extend .flex-direction-column;
  @extend .responsive-width;
  li {
    width: 100%;
  }
}

.User {
  @include border-radius(10px);
  border: 1px solid $border;
  width: 40px;
  height: 40px;
  @extend .display-flex;
  @extend .flex-center;
  position: relative;
  overflow: hidden;
  margin-left: 20px;
}

.Avatar {
  width: 100%;
  position: absolute;
  height: 100%;
}

.Menu {
  position: absolute;
  top: 70px;
  right: 10px;
  z-index: $zi-menu;
  background: $white;
  border: 1px solid $border;
  padding: 10px;
  @include border-radius(10px);
  @extend .display-flex;
  @extend .flex-center;
  @extend .flex-direction-column;
  @extend .bsbb;
  overflow: hidden;
  width: 200px;
  @include box-shadow(0, 0, 5px, 0, $border);
  img {
    width: 60px;
    @include border-radius(50%);
    margin: 10px 0;
  }
  p {
    &:not(:first-of-type) {
      margin-top: 10px;
    }
  }
}

.MenuButton {
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  @extend .bsbb;
  @extend .display-flex;
  @extend .flex-center-start;
  @extend .flex-direction-row;
  text-transform: uppercase;
  @include border-radius(5px);
  i {
    margin-right: 10px;
  }
  &:hover {
    background: $hover;
  }
}
